<!--
    @name: PcParser
    @description：PcParser
    @author: ZengWei
    @date: 2022-03-29 09:30
-->
<template>
  <div
    class="form-parser-container"
    :class="isConsole ? 'console-form-parser-container' : ''"
  >
    <div v-if="skeleton" class="skeleton">
      <skeleton></skeleton>
    </div>
    <div
      v-else
      :class="
        isVerticalShow
          ? 'form-parser-warp is-vertical'
          : ' form-parser-warp is-horizontal'
      "
      :style="activeName === 'second' ? 'padding-bottom: 30px' : ''"
    >
      <div class="left-sider">
        <el-form
          ref="formParser"
          size="medium"
          :class="{ 'form-parser': tableStyle, 'normal-parser': !tableStyle }"
          :model="formValidate"
          :show-message="!tableStyle"
          :label-width="formData.labelWidth + 'px'"
          :label-position="formData?.labelPosition || 'top'"
        >
          <div v-if="formLabel" class="type-label">{{ formLabel }}</div>
          <el-row :gutter="gutter">
            <el-col
              v-for="(item, index) in formValidate.items"
              :span="item.config.__config__.span"
              :key="index"
            >
              <!--兼容隐藏控件的交互事件-->
              <template
                v-if="
                  ['radio', 'switch', 'select'].indexOf(item.type) > -1 &&
                  !showHideField(item.config.__config__)
                "
              >
                {{ initTrigger(item) }}
              </template>

              <template
                v-if="
                  ['row', 'table', 'tab', 'steps', 'text'].includes(
                    item.type
                  ) && showHideField(item.config.__config__)
                "
              >
                <InputItem
                  ref="childParser"
                  :element="item"
                  :disabled="canEditField(item.field)"
                  :editFields="editFields"
                  :formData="formConf"
                  :displayData="displayData"
                  :contentConfig="contentConfig"
                  :dataIds="dataIds"
                  :formMode="formMode"
                  @trigger-active="triggerActive(arguments)"
                  @auto-fill-relate="selectAutoFillRelate"
                >
                </InputItem>
              </template>
              <!--处理子表关联数据验证问题，担心label-width兼容性问题单独写一个elseif @凌志华-->
              <template
                v-else-if="
                  item.type === 'form' && showHideField(item.config.__config__)
                "
              >
                <el-form-item
                  class="no-padding"
                  :prop="'items.' + index + '.value'"
                  :label="''"
                  :rules="item.validate"
                  :label-width="'0px'"
                >
                  <InputItem
                    ref="childParser"
                    :element="item"
                    :disabled="canEditField(item.config.__config__.formId)"
                    :editFields="editFields"
                    :formData="formConf"
                    :displayData="displayData"
                    :contentConfig="contentConfig"
                    :dataIds="dataIds"
                    :formMode="formMode"
                    @trigger-active="triggerActive(arguments)"
                    @auto-fill-relate="selectAutoFillRelate"
                  >
                  </InputItem>
                </el-form-item>
              </template>
              <!--处理子表关联数据验证问题，担心label-width兼容性问题单独写一个elseif-->
              <template v-else-if="showHideField(item.config.__config__)">
                <el-form-item
                  class="no-padding"
                  :prop="'items.' + index + '.value'"
                  :label="item.config.__config__.label"
                  :rules="item.validate"
                  :label-width="
                    item.config.__config__.labelWidth
                      ? item.config.__config__.labelWidth + 'px'
                      : 120 + 'px'
                  "
                >
                  <InputItem
                    ref="childParser"
                    :element="item"
                    :disabled="canEditField(item.config.__config__.formId)"
                    :editFields="editFields"
                    :formData="formConf"
                    :displayData="displayData"
                    :contentConfig="contentConfig"
                    :dataIds="dataIds"
                    :formMode="formMode"
                    @refresh-todo="onRefresh"
                    @trigger-active="triggerActive(arguments)"
                    @auto-fill-relate="selectAutoFillRelate"
                  >
                  </InputItem>
                </el-form-item>
              </template>
            </el-col>
          </el-row>

          <div v-if="$slots.other" class="parser-tp">
            <slot name="other"></slot>
          </div>

          <div v-if="$slots.button" class="parser-tp">
            <slot name="button"></slot>
          </div>

          <div v-if="!hideBtn" style="text-align: right; margin-top: 15px">
            <el-button
              type="default"
              @click="handleReset"
              style="margin-right: 10px"
              >取消</el-button
            >
            <el-button type="primary" @click="handleSubmit">提交</el-button>
          </div>
        </el-form>
      </div>
      <div
        class="right-sider"
        :class="{ 'right-sider-full': drawer }"
        v-show="sideShow && needFlow && formMode !== 'edit'"
      >
        <div class="right-header">
          <el-tabs v-model="activeName">
            <el-tab-pane
              label="流程审批"
              name="first"
              style="display: none"
            ></el-tab-pane>
          </el-tabs>
          <el-button
            v-if="activeName === 'first' && formMode === 'add'"
            type="text"
            @click.stop="flowShow = !flowShow"
          >
            <i class="iconfont iconliucheng" />
            {{ flowShow ? '关闭流程图' : '流程图' }}
          </el-button>
          <span v-else></span>
        </div>
        <div class="right-content" v-if="needFlow">
          <!-- 审批记录 -->
          <template v-if="needFlow && activeName === 'first'">
            <template v-if="formMode === 'add'">
              <FlowDesigner
                v-if="initCanvasData && flowShow"
                style="height: 380px; width: 100%"
                :form-uuid="moduleUuid"
                :flow-data="{ canvas_data: initCanvasData }"
              >
              </FlowDesigner>
              <!--添加界面流程-->
              <FlowPicker
                type="self"
                :module="moduleUuid"
                :flow-edit="editFieldsValue"
                :flowArchiId="flowArchiId"
                :flowParentArchiId="flowParentArchiId"
                :arch-id="oldArchId"
                :lower-flow="lowerFlow"
                @checked-users="dealWithPicker"
                @selected-step="getSelectStep"
              />
            </template>
            <template v-else-if="formMode === 'detail'">
              <!--详情界面流程-->
              <div class="flow-recover-container" v-if="displayData.allLogs">
                <template v-for="(item, index) in displayData.allLogs">
                  <FlowRecover
                    :key="index"
                    :canvas-data="item.recover"
                    :logs="item.logs"
                    :next-step="displayData.nextStep"
                    :current-step="displayData.curStepNode"
                    :flowShow="flowShow"
                  />
                  <el-divider :key="index"></el-divider>
                </template>
              </div>
              <div
                class="flow-recover-container"
                v-else-if="displayData.recover"
              >
                <FlowRecover
                  :canvas-data="displayData.recover"
                  :logs="displayData.logs"
                  :next-step="displayData.nextStep"
                  :current-step="displayData.curStepNode"
                  :flowShow="flowShow"
                />
              </div>
              <el-empty v-else description="暂无流程数据"></el-empty>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import parserMethods from '@/custom-component/form/newParser/scripts/parserMethods';
import skeleton from '@/custom-component/form/newParser/skeleton';
import { calcIF, calcMULTIPLY, calcSUM } from '@/plugins/calcRuleEngine';
import FlowPicker from '@/custom-component/flow/flow-designer/src/operation/FlowPicker';
import FlowRecover from '@/custom-component/flow/flow-designer/src/operation/FlowRecover';
import { getNowTime } from '@/utils/tools';
import { TabPane, Tabs, Empty, Divider } from 'element-ui';
import FlowDesigner from '@/custom-component/flow/flow-designer';
import { isFormCtrl } from '@/custom-component/form/newParser/scripts/tools';

let timer; // 多次触发只执行最后一次定时器
export default {
  name: 'PcParser',
  components: {
    skeleton,
    FlowPicker,
    FlowRecover,
    FlowDesigner,
    'el-tab-pane': TabPane,
    'el-tabs': Tabs,
    'el-empty': Empty,
    'el-divider': Divider,
  },
  props: {
    // 中控台表单回显数据样式有变化
    isConsole: {
      type: Boolean,
      default: false,
    },
    drawer: {
      type: Boolean,
      default: false,
    },
    // 使用下级流程发起
    lowerFlow: {
      type: Boolean,
      default: false,
    },
    // 表单中的组织架构字段
    archField: {
      type: String,
      default: '',
    },
    // 使用下级组织架构ID查找流程
    archiId: {
      type: [String, Number],
      default: '',
    },
    data_id: {
      type: Number,
      default: 0,
    },
    //可编辑字段
    editFields: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    // 显示验证错误信息
    showMessage: {
      type: Boolean,
      default: false,
    },
    // 隐藏默认按钮
    hideBtn: {
      type: Boolean,
      default: true,
    },
    // 表单禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 表单设计数据
    formData: {
      type: Object,
    },
    // 表单回显数据
    displayData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 架构限制
    nowArchiType: {
      type: [String, Number],
      default: '',
    },
    // 表单模式-新增/编辑
    formMode: {
      type: String,
      default: 'add',
    },
    // 表单填充数据
    fillData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 任务表单填充数据
    otherParam: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 是否开启流程
    needFlow: {
      type: Boolean,
      default: false,
    },
    // 表单唯一标识--用于流程
    moduleUuid: {
      type: String,
      default: '',
    },
    contentConfig: {
      type: null,
      default: null,
    },
    dataIds: {
      type: null,
      default: null,
    },
    sideShow: {
      type: Boolean,
      default: true,
    },
    //是否是上面表单，下面是流程
    isVerticalShow: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      parser: this,
    };
  },
  data() {
    return {
      // sideShow: true,
      currentInfo: {},
      skeleton: true,
      formConf: {
        tableStyle: this.formData?.tableStyle || false,
        labelWidth: this.formData?.labelWidth,
        labelPosition: this.formData?.labelPosition,
      },
      formValidate: {
        items: [],
      },
      flowShow: false,
      tdElement: null,
      validator: false,
      formSubmitData: null,
      relateData: [],
      relateItemInd: '',
      relateVisible: false,
      relateConfig: null,
      relateObjectUUid: null,
      relateViewUUid: null,
      relateFilterRule: [],
      formLabel: '',
      tableStyle: false,
      // 流程数据
      selectedUsers: [],
      checkPickerNum: 0,
      selectStep: '',
      flowEngineId: 0,
      // 外部表单
      outerFormIndex: null,
      outerFormData: null,
      outerNeedFlow: false,
      outerFormUuid: '',
      outerObjectUuid: '',
      outerFormMode: 'add',
      outerFormDisplay: {},
      outerFormType: 1,
      outerFormShowType: 1,
      outerFormVisible: false,
      outerFormTitle: '外部表单',

      unsetFlowCtrl: {},
      editFieldsValue: {}, //流程中编辑表单值
      activeName: 'first',
      timer: null,
      oldArchId: '', // 下级流程arch字段变化触发流程变更
      initCanvasData: null, // 初始流程图数据
      newFlow: true,
      flowArchiId: '',
      flowParentArchiId: '',
    };
  },
  computed: {
    gutter() {
      if (this.tableStyle) {
        return 0;
      } else {
        return this.formData.gutter || 15;
      }
    },
    labelWidth() {
      return function (element) {
        if (element.config.__ques__) {
          return '0px';
        }
        const width = element.config.__config__.labelWidth
          ? element.config.__config__.labelWidth + 'px'
          : 120 + 'px';
        return width;
      };
    },
    initTrigger() {
      return function (element) {
        let value = element.value;
        if (element.type === 'steps') {
          value = element.config.__config__.activeName;
        }
        this.triggerEventCtrl(element, value);
      };
    },
    showLabel() {
      return function (config) {
        if (config.showLabel === undefined || config.showLabel === false)
          return '';
        return config.label;
      };
    },
    canEditField() {
      return function (formId) {
        if (this.formMode === 'edit') return this.disabled;
        const editFormId = Object.keys(this.editFields);
        if (editFormId.length > 0 && editFormId.includes(formId)) {
          return !(this.editFields[formId] === 1);
        }
        return this.disabled;
      };
    },
    showHideField() {
      return function (config) {
        //中控台表单将bim,gis 附件等数据提出去,不在表单里显示
        //中控台表单将bim,gis 附件等数据提出去,不在表单里显示
        if (
          ['upload', 'bimList', 'gisList', 'modelView'].includes(
            config.tagIcon
          ) &&
          this.isConsole
        ) {
          return false;
        }
        //中控台表单将bim,gis 附件等数据提出去
        const formId = config.formId;
        if (
          this.formMode === 'edit' ||
          isFormCtrl(this.formData.fields, formId)
        ) {
          return config.addShow;
        }
        const editFormId = Object.keys(this.editFields);
        if (editFormId.includes(formId)) {
          if (Object.keys(this.unsetFlowCtrl).includes(formId)) {
            return this.unsetFlowCtrl[formId];
          }
          return [1, 2].includes(this.editFields[formId]);
        }
        return config.addShow;
      };
    },
    hasDefaultSlot() {
      console.log(this.$slots, 'this.$slots');
      return !!this.$slots.default && this.$slots.default.length > 0;
    },
  },
  watch: {
    formValidate: {
      handler() {
        const that = this;
        clearTimeout(timer);
        timer = setTimeout(() => {
          that.carryCalculate();
          that.editFieldsInput();
        }, 100);
        // if(Object.keys(this.editFields).length){
        //   this.editFieldsInput(); // 改变即获取流程中编辑字段
        // }
        // this.setDynamicData();
      },
      deep: true,
    },
    editFieldsValue: {
      handler(val) {
        this.$emit('edit-change', val);
      },
      deep: true,
    },
  },
  created() {
    window.calcField = this.getFieldValue.bind(this);
    window.calcIF = calcIF;
    window.calcMULTIPLY = calcMULTIPLY;
    window.calcSUM = calcSUM;

    //附加当前填充数据 -- 当前用户，当前时间，当前项目
    const userInfo = this.$GetUserInfo();
    const archiInfo = this.$GetTargetArchi();
    const nowData = getNowTime(1);
    const nowDataTime = getNowTime(2);
    const nowTime = getNowTime(3);
    this.currentInfo = {
      user_id: userInfo.id,
      user_name: userInfo.name,
      archi_id: archiInfo.archi_id,
      archi_name: archiInfo.name,
      company_id: archiInfo.company_id,
      archi_type: archiInfo.archiType,
      section_id: archiInfo.sectionId,
      section_name: archiInfo.sectionName,
      now_date: nowData,
      now_datetime: nowDataTime,
      now_time: nowTime,
    };

    this.getFormValidate(this.formData.fields);
    if (this.moduleUuid && this.needFlow && this.formMode === 'add') {
      if (this.lowerFlow) {
        // 上级人员使用下级组织流程发起
        if (this.archField) {
          const _this = this;
          this.timer = setInterval(() => {
            const formDataValue = _this.getFormData();
            if (formDataValue[_this.archField]) {
              const archiId = formDataValue[_this.archField];
              if (archiId !== _this.oldArchId) {
                _this.oldArchId = archiId;
                const params = {
                  module: this.moduleUuid,
                  archi_id: archiId,
                  archi_table: 'org_projects',
                };
                _this.flowDesign(params);
              }
            }
          }, 100);
        }
      } else {
        this.flowDesign({ module: this.moduleUuid });
      }
    }
    if (this.formData.baseLabel) {
      this.formLabel = '基本信息';
    }
    if (this.formData.tableStyle) {
      this.tableStyle = this.formData.tableStyle;
    }
  },
  mounted() {
    // 解決新版表单与流程操作同级没有provide
    this.$store.commit('setFormInstance', this);
  },
  methods: parserMethods,
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style>
.input-show {
  width: 100%;
  max-height: 100px;
  padding: 5px 12px;
  box-sizing: border-box;
  background: #f2f4f7;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #2a2f3d;
  line-height: 26px;
  cursor: default;
  overflow-y: auto;
}
.el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus {
  border-color: #f56c6c !important;
}

.el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  max-height: 100%;
}

.vertical-center.el-dialog {
  margin: 0 auto !important;
}

.vertical-center .el-dialog__body {
  padding: 15px 20px !important;
}

.el-dialog__body::-webkit-scrollbar,
.el-dialog__wrapper::-webkit-scrollbar,
.el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  margin: 0 3px;
}

.el-dialog::-webkit-scrollbar-track,
.el-dialog__body::-webkit-scrollbar-track,
.el-table__body-wrapper::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.el-dialog::-webkit-scrollbar-thumb,
.el-dialog__body::-webkit-scrollbar-thumb,
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(189, 192, 199, 1);
  border-radius: 4px;
}

.el-dialog::-webkit-scrollbar-thumb:hover,
.el-dialog__body::-webkit-scrollbar-thumb:hover,
.el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgba(189, 192, 199, 1);
}

.el-dialog::-webkit-scrollbar-thumb:active,
.el-dialog__body::-webkit-scrollbar-thumb:active,
.el-table__body-wrapper::-webkit-scrollbar-thumb:active {
  background-color: rgba(189, 192, 199, 1);
}

.el-dialog::-webkit-scrollbar-corner,
.el-dialog__body::-webkit-scrollbar-corner,
.el-table__body-wrapper::-webkit-scrollbar-corner {
  background-color: #ffffff;
}
</style>
<style lang="less" scoped>
.form-parser-container {
  flex-grow: 1;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  .skeleton {
    width: 100%;
  }
  .is-vertical {
    width: 100%;
    flex-direction: column !important;
    overflow-x: hidden !important;
    .left-sider {
      width: 100%;
      height: auto;
    }
    .right-sider {
      border: none !important;
      width: 100% !important;
      padding: 0 !important;
      overflow: hidden !important;
      .flow-recover-container {
        padding-left: 0px !important;
        .flow-designer {
          width: 360px !important;
        }
      }
    }
  }
  .is-horizontal {
    .left-sider {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .form-parser-warp {
    flex-grow: 1;
    flex-direction: row;
    display: flex;
    height: 100%;
    overflow: auto;
    /* 当前样式注释代码均为左右结构调整为上下结构 */
    .left-sider {
      flex: 1;
      padding: 15px;
      box-sizing: border-box;

      position: relative;
      .shrink-icon {
        position: absolute;
        top: 13px;
        right: -6px;
        width: 27px;
        z-index: 101;
        font-size: 24px;
        animation: fade 0.6s infinite;
      }

      :deep(.el-form--label-top) {
        .el-form-item__label {
          padding-bottom: 8px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #2a2f3d;
          line-height: 22px;
        }
        .el-row {
          display: flex;
          flex-wrap: wrap;
        }
        .el-col {
          float: none;
        }
        .reshow-text {
          padding: 5px 12px;
          background: #f2f4f7;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2a2f3d;
          line-height: 22px;
        }
      }
    }
    .right-sider {
      width: 340px;
      margin-left: 5px;
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      box-sizing: border-box;
      border-left: 1px solid #f1f1f1;

      .right-header {
        display: flex;
        padding: 0 10px;
        box-sizing: border-box;
        align-items: center;
        :deep(.el-tabs--top) {
          flex-grow: 1;
          .el-tabs__header {
            margin-bottom: 12px;
            .el-tabs__nav-wrap {
              &::after {
                background: none;
              }
            }
          }
        }
      }
      .right-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding: 0 5px;
        box-sizing: border-box;
        .flow-recover-container {
          flex-grow: 1;
          overflow: auto;
          padding-left: 12px;
          padding-right: 10px;
          box-sizing: border-box;
          padding-bottom: 72px;
          box-sizing: border-box;
        }
        .flow-operate-container {
          height: 72px;
          width: 100%;
          padding-top: 8px;
          box-sizing: border-box;
          background: #fff;
          z-index: 100;
        }
      }
    }
    .right-sider-full {
      width: 98%;
      border-left: none;
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: width 0.5s;
      opacity: 0;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      width: 0;
      opacity: 0;
    }
  }
}

// 中控台引入表单的样式
.console-form-parser-container {
  .form-parser-warp {
    flex-direction: column;
    .left-sider {
      padding: 0;
      width: 100%;
    }
    .right-sider {
      width: 100%;
      margin: 0;
      border: none;
      .flow-recover-container {
        padding: 0 !important;
      }
    }
  }
}
</style>
<style lang="less" scoped>
@colorActive: #007fe0;
.parser-tp {
  margin-top: 20px;
}
:deep(.type-label) {
  position: static;
  border-left: 3px solid var(--themeColor) !important;
  border-top: 5px solid transparent;
  height: 38px;
  background: linear-gradient(270deg, rgba(232, 240, 254, 0) 0%, #e8f0fe 100%);
  background-clip: padding-box;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: var(--themeColor);
  line-height: 35px !important;
  margin: 8px 0;
}
:deep(.form-parser) {
  .table-wrapper,
  .table-relate {
    .el-table {
      .el-form-item {
        border: none;
      }
    }
  }
  .el-form-item {
    border: 1px solid #eaebed;
    display: flex;
    margin-bottom: -1px !important;
    .el-form-item__label {
      border-right: 1px solid #eaebed;
      background: #f8f8f9;
      padding: 6px 10px;
    }
    .el-form-item__content {
      margin-left: 0 !important;
      flex: 1;
      padding: 6px 10px;
      overflow: hidden;
      clear: both;
      .el-select,
      .el-cascader {
        width: 100%;
      }
      .el-input.is-disabled .el-input__inner {
        background-color: #ffffff;
        color: #606266;
      }
      .el-textarea.is-disabled .el-textarea__inner {
        color: #606266;
      }
      .el-input-group__prepend,
      .el-input-group__append {
        border: none;
      }
      .el-input__inner,
      textarea {
        border: 1px solid transparent;
      }
    }
  }
}
:deep(.normal-parser) {
  .el-col-1 {
    .el-form-item {
      height: 36px;
      overflow: hidden;
    }
  }
  // .el-form-item__label {
  //   //padding-left: 10px;
  // }

  /*固定顶部对齐，新版表单配置左右对齐，导致label不展示*/
  /*.el-form-item__content {
    margin-left: 0 !important;
  }*/

  .el-select,
  .el-cascader {
    width: 100%;
  }
  .table-wrapper,
  .table-relate {
    margin-bottom: 18px;
  }
  .el-input.is-disabled .el-input__inner {
    color: #606266;
  }
  .el-textarea.is-disabled .el-textarea__inner {
    color: #606266;
  }
}
:deep(.common-dialog) {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  .dialog-main {
    overflow-x: auto;
  }
  .el-dialog__body {
    max-height: calc(100vh - 155px);
    height: calc(100vh - 155px);
    min-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
