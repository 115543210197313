<!--
 * @Description: tabs
 * @Author: luocheng
 * @Date: 2021-10-08 17:11:12
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-09-12 17:52:47
-->
<template>
  <div v-loading="loading" v-if="Object.keys(riskData).length" class="risk-schedule-cont">
    <ul class="risk-block" v-for="(item,index) in riskData.data" :key="index">
      <li v-for="(val,j) in item.data" :key="j" :style="getStyle(item.type, val)">
        <span v-if="getStatus(val)">{{ val.name }}</span>
        <el-tooltip v-else :content="val.name" placement="top" effect="light">
          <div class="no-text-box"></div>
        </el-tooltip>
      </li>
    </ul>
    <div id="progress-cont">
      <p class="total-desc">{{ `总${baseConfig.unit}数：${riskData.total}${baseConfig.unit}` }}</p>
      <div class="actual-div" :style="{width:getWidth,background:baseConfig.actualColor}">
        <span v-if="getBlockStatus(`开累${riskData.actual }${baseConfig.unit}`,1)">开累{{ riskData.actual }}{{ baseConfig.unit }}</span>
        <el-tooltip v-else :content="`开累${riskData.actual }${baseConfig.unit}`" placement="top" effect="light">
          <div class="no-text-box"></div>
        </el-tooltip>
      </div>
      <div class="residue-div" :style="{background:baseConfig.baseColor}">
        <span v-if="getBlockStatus(`剩余${(riskData.total - riskData.actual).toFixed(0)}${baseConfig.unit}`,2)">剩余{{ (riskData.total - riskData.actual).toFixed(0) }}{{ baseConfig.unit }}</span>
        <el-tooltip v-else :content="`剩余${(riskData.total - riskData.actual).toFixed(0)}${baseConfig.unit}`" placement="top" effect="light">
          <div class="no-text-box"></div>
        </el-tooltip>
      </div>
    </div>
  </div>
  <Empty v-else description="暂无数据"  :image-size="100"></Empty>
</template>

<script>
import { dataInterface } from '@/apis/data/index';
import { initParams } from '@/utils/tools';
import { mapState } from 'vuex';
import { Tooltip, Empty } from 'element-ui';

export default {
  name: 'CommonRiskSchedule',
  components:{
    Empty,
    "el-tooltip" : Tooltip,
  },
  props: {
    element: {
      type: Object,
      required: false,
      default: () => {}
    },
    componentList: {
      default: null
    },
    // 是否为分组
    isGroup: {
      type: Boolean,
    },
    // 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  inject: ['EDITOR_pageUUID'],
  data() {
    return {
      interfaceFinished:true,
      loading: false,
      comstomColor:['#33ffff','#3399cc','#009999','#00ff33','#666633','#9966cc','#99cc00','#2474D7','#18EAEA','#18EA53','#CACC00','#CB9A60'],
      riskData:{}
    };
  },
  watch: {
  },
 
  computed: {
    ...mapState(['componentData', 'subsidiaryComponentData']),
    baseConfig(){
      return this.element.baseConfig;
    },
    // 嵌入页面的参数获取
    subComponentData() {
      if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
    },
    database() {
			return this.element && this.element.database;
		},
    getWidth(){
      return `${(this.element.style.width/this.riskData.total*this.riskData.actual).toFixed(0)}px`
    },
    getStyle() { 
      return function (type, data) {
        let style = {
          width:0,
          background: +type === 1? this.element.baseConfig.waringColor: this.comstomColor[Math.floor(Math.random() * 11  + 1)],
          left: 0
        }
        const scale = this.element.style.width/this.riskData.total;
        style.left = `${(data.begin * scale).toFixed(0)}px`;
        style.width = `${((data.end - data.begin) * scale).toFixed(0)}px`;
        return style
      }
    },
    getStatus() {
      return function (data) {
        const scale = this.element.style.width/this.riskData.total;
        const boxWidth = ((data.end - data.begin) * scale).toFixed(0);
        const fontWidth = data.name.length * 12;
        return fontWidth < boxWidth
      }
    },
    getBlockStatus() {
      return function (name,type) {
        let boxWidth =  (this.element.style.width/this.riskData.total*this.riskData.actual).toFixed(0);
        if(type === 2){
          boxWidth =  (this.element.style.width/this.riskData.total*(this.riskData.total - this.riskData.actual)).toFixed(0);
        }
        const fontWidth = name.length * 14;
        return fontWidth < boxWidth
      }
    }
  },
  mounted() {
    if (!this.interfaceFinished) return;
      this.interfaceFinished = false;
      const {
          search = [],
          param = {},
          canPost
        } = initParams(
          this.element?.database?.paramsConfig || [],
          this.isGroup,
          this.subComponentData,
          this.groupComponents,
					this.element
        );
        if (!canPost) {
          this._failDataDebug('参数必填校验未通过');
          return;
        }
        this.getList(this.element.database, search, param);
    },
  methods: {
    /**
		 * @desc: 获取渲染列表
		 * @param {Object} database 数据配置对象
		 * @param {Array} search 搜索
		 */
		getList(database, search = [], params = {}) {
			// this.riskData = [];
			// 外部参数
			// 注入的参数
			let outParams = {};
			if (this.element.database.userOutParams) {
				outParams = sessionStorage.getItem(
					`dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
				);
				outParams = outParams ? JSON.parse(outParams) : {};
			}
			this.loading = true;
			const paramsObj = {
				...params,
				...outParams,
				search
			};
			// 配置
			let __method_name__ = 'dataList';
			const mapping = database.mapping;
			let configObj = null;
			let url = '/api/mapi';
			if (mapping === 'interface') {
				configObj = {
					...paramsObj
				}
				if (database?.interfaceConfig?.url?.indexOf('http') === -1) {
					url = `/api${database?.interfaceConfig?.url}`;
				} else {
					url = database?.interfaceConfig?.url
				}
			} else if (mapping === 'object') {
				configObj = {
					__method_name__,
					object_uuid: database.objectData.uuid,
					view_uuid: database.viewData.uuid,
					...paramsObj
				};
			} else if (mapping === 'relation') {
				__method_name__ = 'relationList';
				configObj = {
					__method_name__: 'relationList',
					object_uuid: database.objectData.uuid,
					relationship_uuid: database.relationData.uuid,
					...paramsObj
				};
			}
			dataInterface(configObj, url).then((res) => {
        this.interfaceFinished = true;
				if (res && res.status === 200 && res.data.data?.length) {
          this.riskData = res.data.data[0];
        }
        this.loading = false;
			}).catch((err) => {
				console.log(err);
				this.interfaceFinished = true;
				this.loading = false;
				this._errorDataDebug({
					url,
					content: configObj,
					err
				})
			});
		},
  },
  beforeDestroy() {
  }
};
</script>

<style lang="less" scoped>
  .risk-schedule-cont{
    width: 100%;
    height: 100%;
    position:relative;
    overflow: hidden;
    .risk-block{
      margin-bottom: 10px;
      height: 22px;
      position: relative;
      li{
        height: 22px;
        border-radius: 4px;
        font-size: 12px;
        text-align: center;
        color: #fff;
        line-height: 22px;
        position: absolute;
        top: 0;
        overflow: hidden;
      }
    }
    .no-text-box{
      width: 100%;
      height: 100%;
    }
    #progress-cont{
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      font-size: 14px;
      color: #fff;
      margin-top: 40px;
      position: relative;
      .total-desc{
        position: absolute;
        right: 5px;
        top: -14px;
        font-size: 12px;
      }
      .actual-div{
        border-radius: 4px 0 0 4px;
        line-height: 40px;
        text-align: center;
        position: relative;
      }
      .actual-div::after{
        content: '';
        width: 3px;
        height: 1000px;
        position: absolute;
        right: -1px;
        bottom: 0;
        background-color: salmon;
      }
      .residue-div{
        border-radius:0 4px 4px 0;
        line-height: 40px;
        flex: 1;
        text-align: center;
      }
    }
  }
</style>
