<!--
 * @Author: your name
 * @Date: 2021-12-17 14:02:47
 * @LastEditTime: 2024-09-19 10:59:03
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \dataview-viewer-test\src\components\fileUpload\newFiles\index.vue
-->
<template>
  <div v-loading="uploadLoading" class="file-upload">
    <el-upload
      v-if="!disabled"
      :action="uploadURL"
      :before-upload="beforeUpload"
      class="upload-style"
      :class="drag && isImgUpload ? 'upload-drag-img' : ''"
      :data="{ token }"
      :multiple="multiple"
      :on-success="onSuccess"
      :on-change="getModelFile"
      :show-file-list="uploading"
      :limit="goalType === 'default' ? 100 : 1"
      :drag="drag"
      :headers="{
        metadatatoken: '4fb0b638-2c43-4d7e-8acf-f1a7abdee58easdfasdfqwerqwer',
      }"
      :accept="fileAccept"
    >
      <div ref="uploadRef" :style="drag ? '' : 'text-align: left'">
        <el-button v-if="!drag && !isImgUpload" :icon="icon" type="default">
          <i class="iconfont iconjijia_shangchuan" style="font-size: 16px"></i>
          {{ btnName }}
        </el-button>
        <p
          v-if="fileTypeList.length > 0 && !isImgUpload"
          style="line-height: 24px"
        >
          &nbsp;仅支持
          <span style="color: red">{{ fileTypeList.join('，') }}</span>
          格式文件上传
        </p>
        <div v-if="drag && !isImgUpload" class="drag-text">
          <i
            class="iconfont iconjijia_shangchuan"
            style="font-size: 24px; display: block; color: #5da6fa"
          />
          <p style="font-size: 14px; font-weight: 400">
            <span style="color: #202126">拖拽上传</span>
            <span style="color: #8a8f99; margin: 0 8px">或</span>
            <span style="color: #5da6fa">点击上传</span>
          </p>
        </div>
        <div v-if="drag && isImgUpload" class="drag-img">
          <div class="drag-img-item">
            <i class="iconfont iconxiangjifill"></i>
            <p>拖拽图片</p>
            <p>或点击上传</p>
          </div>
          <div
            class="drag-img-item"
            ref="copyRef"
            @mouseover="copyImg"
            @mouseout="removeListener"
            @click.stop="preventDefault"
          >
            <i class="iconfont icontupian"></i>
            <p>点击此处</p>
            <p>粘贴(Ctrl+V)截图</p>
            <input class="copy-input" maxlength="0" @click="copyImg" />
          </div>
        </div>
      </div>
    </el-upload>
    <div v-if="imageArr && imageArr.length" class="upload-img-area">
      <div
        class="upload-img-item"
        v-for="(item, index) in imageArr"
        :key="index"
      >
        <el-image
          v-if="onlyImgUrl"
          :src="item"
          :preview-src-list="previewList(index)"
          style="width: 100%; height: 100%"
          @click="handleView(index)"
        />
        <el-image
          v-else
          :src="item.thumb_path"
          :preview-src-list="previewList(index)"
          style="width: 100%; height: 100%"
          @click="handleView(index)"
        />
        <div v-show="!disabled" class="del-image">
          <i
            class="iconfont iconshanchu"
            style="color: #fff; font-size: 18"
            @click.stop="handleRemoveImg(index)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="Array.isArray(fileArr) && fileArr.length"
      class="upload-files-area"
    >
      <div class="file-show">
        <div
          v-for="(item, index) in fileArr"
          :key="item.filepath"
          class="file-item"
        >
          <div class="file-item-left" @click="openFile(item)">
            <svg aria-hidden="true" class="iconfont-svg">
              <use xlink:href="#iconfujian1" />
            </svg>
            <div class="name">
              {{ getFileName(item.filename, item).name }}
            </div>
            <div class="type">{{ getFileName(item.filename, item).type }}</div>
          </div>
          <div class="file-item-right">
            <span class="file-item-view" @click="openFile(item)"> 预览 </span>
            <span
              v-if="
                (!formMode || formMode !== 'add') &&
                onlyoffice &&
                [
                  'doc',
                  'docx',
                  'pdf',
                  'ppt',
                  'pptx',
                  'xlsx',
                  'xls',
                  'txt',
                ].indexOf(
                  item.path
                    .substring(item.path.lastIndexOf('.') + 1)
                    .toLowerCase()
                )
              "
              class="file-item-view"
              @click="editFile(item)"
            >
              编辑
            </span>
            <span
              class="file-item-view"
              @click="downloadFile(item.filepath, item.name ?? item.filename)"
            >
              下载
            </span>
            <span
              v-if="disabled ? false : true"
              class="file-item-del"
              @click="handleRemove(index)"
            >
              删除
            </span>
          </div>
          <div class="file-item-size">
            {{ getFileSize(item.filesize) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        disabled &&
        (!fileArr || fileArr.length <= 0) &&
        (!imageArr || imageArr.length <= 0)
      "
    >
      无
    </div>
    <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
      <div style="width: 100%; height: 100%">
        <el-image fit="fill" :src="dialogImageUrl" />
      </div>
    </el-dialog>
    <div v-if="drawingShow" class="drawing-cont">
      <div :id="'viewerDom' + viewerId" style="width: 100%; height: 100%" />
      <i @click="closeDrawing" class="iconfont iconc-close"></i>
    </div>
  </div>
</template>
<script>
import { parseTime, getToken } from '@/utils/tools';
import { baseUrl } from '@/apis/http/request';
import { Dialog, Image, Upload } from 'element-ui';
import { downloadFileBlob } from '@/utils/tools';
import axios from 'axios';

let viewer;
export default {
  props: {
    // 文件
    files: {
      type: [Array, String],
      default() {
        return null;
      },
    },
    // 禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 按钮名
    btnName: {
      type: String,
      default: '上传文件',
    },
    // icon
    icon: {
      type: String,
      default: '',
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: true,
    },
    // 允许上传的文件格式
    fileTypeList: {
      type: Array,
      default: () => [],
    },
    // 文件名超出最长长度后中间显示省略号
    nameMaxLength: {
      type: Number,
      default: 20,
    },
    // 拖拽上传
    drag: {
      type: Boolean,
      default: false,
    },
    // 仅图片地址数组(全路径)
    onlyImgUrl: {
      type: Boolean,
      default: false,
    },
    // 限制文件类型
    fileAccept: {
      type: String,
      required: false,
      default: '',
    },
    //上传类型
    goalType: {
      type: String,
      default: 'default',
    },
    // 文件预览编辑
    onlyoffice: {
      type: Boolean,
      default: false,
    },
    // 文件编辑使用uuid
    element: {
      type: Object,
      default: null,
    },
    // 文件编辑使用uuid
    objectUuid: {
      type: String,
      default: '',
    },
    // 详情id
    dataIds: {
      type: null,
      default: null,
    },
    // 表单类型
    formMode: {
      type: null,
      default: null,
    },
  },
  components: {
    'el-dialog': Dialog,
    'el-upload': Upload,
    'el-image': Image,
  },
  // emits: ['extra-files'],
  data() {
    return {
      extra_file: [],
      uploading: false,
      currentUser: '', //当前用户
      imageArr: [], // 图片数组
      fileArr: [], //文件数组
      url: baseUrl,
      dialogVisible: false,
      dialogImageUrl: '',
      uploadURL: `${baseUrl}api/mapi?__method_name__=file&token=${getToken()}`,
      token: getToken(),
      imageType: ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'],
      uploadLoading: false,
      viewerId: '',
      drawingShow: false,
    };
  },
  computed: {
    isImgUpload() {
      if (!this.fileTypeList?.length) {
        return false;
      } else {
        let num = 0;
        let otherNum = 0;
        const imgStr = 'png,jpg,jpeg,gif';
        this.fileTypeList.forEach((item) => {
          if (imgStr.indexOf(item) !== -1) {
            num += 1;
          } else {
            otherNum += 1;
          }
        });
        if (num <= 4 && otherNum === 0) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  watch: {
    files: {
      handler(files) {
        if (this.onlyImgUrl) {
          this.imageArr = files;
        } else {
          this.imageArr = [];
          this.fileArr = [];
          if (Array.isArray(files) && files && files.length !== 0) {
            files.map((item) => {
              if (typeof item === 'object') {
                if (
                  (item.filepath &&
                    this.imageType.indexOf(
                      item.filepath
                        .substring(item.filepath.lastIndexOf('.') + 1)
                        .toLowerCase()
                    ) > -1) ||
                  (item.fileext &&
                    this.imageType.indexOf(item.fileext.toLowerCase()) > -1) // TIPS 这里是为了解决视频组件截图路径带参数的问题
                ) {
                  this.imageArr.push(item);
                } else {
                  this.fileArr.push(item);
                }
              } else {
                this.imageArr.push({
                  name: '',
                  upUser: '未知',
                  upTime: parseTime(new Date()),
                  path: item,
                });
              }
            });
            this.extra_file = files;
          } else if (typeof files === 'string') {
            let isImg = false;
            const imgTypes = ['jpg', 'jpeg', 'png', 'gif'];
            imgTypes.forEach((element) => {
              if (files.indexOf(element) > -1) {
                isImg = true;
              }
            });
            if (isImg) {
              this.imageArr.push({
                name: '',
                upUser: '未知',
                upTime: parseTime(new Date()),
                path: files,
                filepath: files,
              });
              this.extra_file.push({
                name: '',
                upUser: '未知',
                upTime: parseTime(new Date()),
                path: files,
                filepath: files,
              });
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
    extra_file: {
      handler(data) {
        this.loading = false;
        this.$emit('extra-files', data, this.index);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 预览列表
    previewList(index) {
      if (!this.imageArr || !Array.isArray(this.imageArr)) return [];
      if (this.onlyImgUrl) return this.imageArr;
      const imgArr = this.imageArr.map((ele) => {
        const isThumb = ele.filepath.indexOf('_thumb') !== -1;
        let str = '';
        if (isThumb) {
          let path = ele.filepath.split('_thumb');
          if (path[1] && path[1] !== 'undefined') {
            str = path[0] + '' + path[1];
          } else {
            str = path[0];
          }
        } else {
          str = ele.filepath;
        }
        return str;
      });
      return index || index === 0 ? [imgArr[index]] : imgArr;
    },
    /**
     * @description 上传成功回调
     * @param {*} response
     * @param {*} file
     * @param {*} fileList
     */
    onSuccess(res) {
      this.uploading = false;
      if (!Array.isArray(this.extra_file)) {
        this.extra_file = [];
      }
      /* this.extra_file.push({
          name: file.name,
          upUser: this.currentUser,
          upTime: parseTime(new Date()),
          path: response.data.filepath,
        }) */

      this.uploading = false;
      if (this.extra_file == null) {
        this.extra_file = new Array();
      }
      //判断上传文件是否为图片
      res.data.upTime = parseTime(new Date());
      res.data.name = res.data.filename;
      if (this.imageType.indexOf(res.data.fileext.toLowerCase()) > -1) {
        res.data.path = res.data.thumb_path;
      } else {
        res.data.path = res.data.filepath;
      }
      this.extra_file.push(res.data);
    },
    /**
     * @description 选中文件回调
     */
    getModelFile(file) {
      if (!['default', 'schedule'].includes(this.goalType)) {
        this.$emit('extra-files', file);
        return false;
      }
    },
    /**
     * @description 上传前置钩子
     */
    beforeUpload(file) {
      //需要单独处理的上传方式
      if (!['default', 'schedule'].includes(this.goalType)) {
        return false;
      }
      this.uploading = true;
      if (this.fileTypeList.length > 0) {
        let arr = [];
        this.fileTypeList.forEach((item) => {
          if (file.name.indexOf(item) > -1) {
            arr.push(file.name);
          }
        });
        if (arr.length == 0) {
          this.$message.error(
            '请上传 ' + this.fileTypeList.join(',') + ' 格式的文件！'
          );
          return false;
        }
      }
    },
    /**
     * @description 查看图片
     * @param {Number} index
     */
    handleView(index) {
      const isThumb = this.imageArr[index].filepath.indexOf('_thumb') !== -1;
      let str = '';
      if (isThumb) {
        let path = this.imageArr[index].filepath.split('_thumb');
        if (path[1] && path[1] !== 'undefined') {
          str = path[0] + '' + path[1];
        } else {
          str = path[0];
        }
      } else {
        str = this.imageArr[index].filepath;
      }
      this.dialogImageUrl = str;
      this.dialogVisible = true;
      // 暂时没有实现
      // let arr=[]
      // this.imageArr.map(item=>{
      //     arr.push(item.path)
      // })
      // this.$store.commit('setImageUrls', {data: arr,index :index})
    },
    /**
     * @description 查看文件预览
     * @param {Object} row
     */
    openFile(row) {
      if (
        ['bmp', 'jpg', 'png', 'gif', 'jpeg', 'cdr', 'psd'].indexOf(
          row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
        ) >= 0
      ) {
        // this.$store.commit('setImageUrls', { data: [row.path] })
        this.dialogImageUrl = row.path;
        this.dialogVisible = true;
      } else if (
        ['doc', 'docx', 'pdf', 'ppt', 'pptx', 'xlsx', 'xls', 'txt'].indexOf(
          row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
        ) >= 0
      ) {
        let url = 'https://weboffice.bimcc.net?furl=';
        if (row.path.includes('https://')) {
          url = 'https://weboffice.bimcc.net?ssl=1&furl=';
        }
        window.open(url + row.path);
      } else if (
        ['mp4'].indexOf(
          row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
        ) >= 0
      ) {
        window.open(row.path);
      } else if (
        ['dwg'].indexOf(
          row.path.substring(row.path.lastIndexOf('.') + 1).toLowerCase()
        ) >= 0
      ) {
        axios({
          method: 'post',
          url: baseUrl + 'api/model/auth/token',
          headers: {
            Bimcctoken: getToken(),
          },
        }).then((res) => {
          if (res.data?.data?.token) {
            axios({
              method: 'get',
              url:
                'https://models.bimcc.net/api/v3/model/tranlate?file_id=' +
                row.model_file_id,
              headers: {
                authorization: `Bearer ${res.data.data.token}`,
              },
            }).then((re) => {
              if (re.data.data?.progress === 'Finish') {
                let modelId = '';
                if (
                  re.data?.data?.data?.f2d &&
                  re.data.data.data.f2d.length !== 0
                ) {
                  this.viewerId = row.id;
                  modelId = re.data.data.data.f2d[0].model_id;
                  this.drawingShow = true;
                  setTimeout(() => {
                    const appId = 'bdafce12a2d9465d89821ec14214a382';
                    const appSecret = '3EYcdAjbnWxP';
                    viewer = new window.BIMCC.Viewer('viewerDom' + row.id, {});
                    viewer.clearUI();
                    viewer.init(modelId, {
                      appId,
                      appSecret,
                      UIType: 'UI.Base',
                    });
                  }, 100);
                }
              } else {
                this.$message.info('dwg文件转换中,请稍后再试！');
              }
            });
          } else {
            this.$message.info('请稍后再试！');
          }
        });
      } else {
        this.$message.info('无法打开此格式的文件！');
      }
    },
    /**
     * @description 编辑文件
     * @param {row} 文件对象
     */
    editFile(row) {
      console.log(this.element);
      if (this.element?.field) {
        window.open(
          `${this.url}onlyOffice/edit?objectUuid=${this.objectUuid}&fieldUuid=${this.element.field}&dataId=${this.dataIds}&fileId=${row.id}`
        );
      }
    },
    /**
     * @description 移除图片
     * @param {Number} index
     */
    handleRemoveImg(index) {
      this.imageArr.splice(index, 1);
      this.extra_file = [].concat(this.imageArr, this.fileArr);
    },
    /**
     * @description 移除文件
     * @param {Number} index
     */
    handleRemove(index) {
      this.fileArr.splice(index, 1);
      this.extra_file = [].concat(this.imageArr, this.fileArr);
    },
    /**
     * @description 文件名字截取
     * @param {String} name
     */
    getFileName(name, item) {
      if (name.includes('.')) {
        let type = '.' + name.split('.')[name.split('.')?.length - 1];
        const title = name.substring(0, name.length - type.length - 1);
        type = name[title.length] + type;
        return {
          type,
          name: title,
        };
      } else {
        return {
          type: '.' + item.filetype,
          name: name,
        };
      }
    },
    /**
     * @description 文件大小显示
     * @param {Number} fileSize
     */
    getFileSize(fileSize) {
      if (fileSize < 1024) {
        return fileSize + 'B';
      } else if (fileSize < 1024 * 1024) {
        let temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
      } else if (fileSize < 1024 * 1024 * 1024) {
        let temp = fileSize / (1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
      } else {
        let temp = fileSize / (1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
      }
    },
    /**
     * @description 文件下载
     * @param {String, String} path, name
     */
    async downloadFile(url, name) {
      this.uploadLoading = true;
      await downloadFileBlob(url, name);
      this.uploadLoading = false;
    },
    /**
     * @description 关闭图纸预览
     */
    closeDrawing() {
      this.drawingShow = false;
      viewer = null;
    },
    preventDefault(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    copyImg() {
      this.$refs.copyRef.addEventListener('paste', this.getClipboardFiles);
    },
    removeListener() {
      this.$refs.copyRef.removeEventListener(
        'paste',
        this.getClipboardFiles,
        false
      );
    },

    getClipboardFiles(event) {
      let items = event.clipboardData && event.clipboardData.items;
      let file = null;
      if (items && items.length) {
        // 检索剪切板items
        for (var i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            file = items[i].getAsFile();
          }
        }
      }
      if (file?.type?.indexOf('image') === -1) {
        this.$message.error('粘贴内容不是图片');
      } else {
        if (this.fileTypeList.indexOf(file?.type.split('/')[1]) === -1) {
          this.$message.error('限制此类型图片上传！');
          return;
        }
        this.handleFilesAdd(file);
      }
    },
    handleFilesAdd(file) {
      const formData = new FormData();
      formData.append('file', file);
      axios({
        method: 'post',
        url: this.uploadURL,
        data: formData,
      }).then((res) => {
        if (res.status === 200) {
          this.onSuccess(res.data);
        } else {
          this.$message.error('上传截图失败！');
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.file-upload {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  .upload-style {
    height: auto;
    .drag-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      p {
        white-space: nowrap;
      }
    }
    .drag-img {
      display: flex;
      justify-content: flex-start;
      .drag-img-item {
        width: 104px;
        height: 104px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #d7dae0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        position: relative;
        i {
          width: 32px;
          height: 32px;
          font-size: 32px;
          color: #d7dae0;
        }
        p {
          line-height: 18px;
          font-size: 12px;
          color: #6d768f;
        }
      }
      .copy-input {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 104px;
        height: 104px;
        outline: none;
        border: none;
        background: transparent;
        text-align: center;
        caret-color: transparent;
        color: transparent;
        text-shadow: 0px0px0px#333;
      }
    }
    :deep(.el-upload) {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
      }
    }
  }
  .upload-drag-img {
    :deep(.el-upload) {
      width: 100%;
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none !important;
      }
    }
  }
  .upload-img-area {
    width: 100%;
    height: auto;
    margin: 10px 0;
    .upload-img-item {
      display: inline-block;
      width: 48px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      border: 1px solid transparent;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      position: relative;
      margin-right: 4px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      &:hover .del-image {
        display: inline-block;
      }
      .del-image {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        background: #d3d4d5;
        color: #fff;
        line-height: 16px;
      }
    }
  }
  .upload-files-area {
    .file-name {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 23px);
      vertical-align: middle;
    }
    .file-show {
      font-size: 12px;
      .file-item {
        display: flex;
        height: 30px;
        line-height: 30px;
        justify-content: space-between;
        &:hover .file-item-left {
          color: #087aff;
        }
        &:hover .file-item-size {
          display: none;
        }
        &:hover .file-item-right {
          display: flex;
          justify-content: flex-end;
        }
        .file-item-left {
          flex: 1;
          display: flex;
          align-items: center;
          overflow: hidden;
          .iconfont-svg {
            width: 16px;
            height: 16px;
            background-color: transparent;
            margin-right: 5px;
          }
          .name {
            width: auto;
            max-width: calc(100% - 80px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .type {
            min-width: 60px;
          }
        }
        .file-item-right {
          width: auto;
          display: none;
          .file-item-view {
            color: #087aff;
            padding-right: 3px;
            cursor: pointer;
            &:hover {
              color: #5da6fa;
            }
          }
          a {
            padding-right: 3px;
            &:hover {
              color: #5da6fa;
            }
          }
          .file-item-del {
            color: #ff0000;
            cursor: pointer;
            &:hover {
              color: #f54f4f;
            }
          }
        }
      }
    }
  }
  .no-data {
    padding: 0 20px;
    color: #909399;
  }
}
.drawing-cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1000;
  i {
    position: absolute;
    top: 0px;
    right: 5px;
    font-size: 25px;
  }
}
</style>
