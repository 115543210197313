<!--
    @name: widget-outerForm
    @description：widget-outerForm
    @author: ZengWei
    @date: 2022-03-25 09:43
-->
<template>
  <div>
    <el-button
      type="primary"
      icon="el-icon-search"
      :loading="loading"
      @click="openOuterForm"
      :disabled="btnDisabled"
    >
      {{ btnDisabled ? '未发起' : element.config.__slot__.default }}
    </el-button>

    <el-dialog
      v-if="outerFormShowType === 1"
      top="5vh"
      :title="outerFormTitle"
      :custom-class="'common-dialog'"
      :visible.sync="outerFormVisible"
      width="80%"
      append-to-body
      :before-close="closeModal"
      :close-on-click-modal="false"
    >
      <article class="dialog-main">
        <template v-if="outerNewFlow">
          <PcParserNew
            v-if="outerFormData && outerFormVisible"
            ref="outerFormParser"
            @submit="outerFormSubmit"
            @changeSkeleton="changeSkeleton"
            :form-data="outerFormData"
            :displayData="outerFormDisplay"
            :needFlow="outerNeedFlow"
            :moduleUuid="outerFormUuid"
            :formMode="outerFormMode"
            :disabled="canEditField(element.config.__config__.formId)"
            :editFields="editFieldsVal"
          >
          </PcParserNew>
        </template>
        <template v-else>
          <PcParser
            ref="outerFormParser"
            v-if="outerFormData && outerFormVisible"
            :hide-btn="true"
            :form-data="outerFormData"
            :form-mode="outerFormMode"
            :need-flow="outerNeedFlow"
            :module-uuid="outerFormUuid"
            :display-data="outerFormDisplay"
            :disabled="canEditField(element.config.__config__.formId)"
            @submit="outerFormSubmit"
            :editFields="editFieldsVal"
          ></PcParser>
        </template>
      </article>
      <div
        slot="footer"
        v-if="
          !canEditField(element.config.__config__.formId) &&
          element.config.__slot__.default !== '点击查看'
        "
      >
        <el-button type="default" @click="closeModal" style="margin-right: 10px"
          >取消</el-button
        >
        <el-button type="primary" @click="confirmSubmit()">确认</el-button>
      </div>
    </el-dialog>

    <el-drawer
      v-if="outerFormShowType === 2"
      :title="outerFormTitle"
      :visible.sync="outerFormVisible"
      direction="rtl"
      size="60%"
    >
      <article class="dialog-main">
        <PcParser
          ref="outerFormDrawer"
          v-if="outerFormData && outerFormVisible"
          :hide-btn="!canEditField(element.config.__config__.formId)"
          :form-data="outerFormData"
          :form-mode="outerFormMode"
          :need-flow="outerNeedFlow"
          :module-uuid="outerFormUuid"
          :display-data="outerFormDisplay"
          :disabled="canEditField(element.config.__config__.formId)"
          @submit="outerFormSubmit"
          :editFields="editFieldsVal"
        ></PcParser>
      </article>
    </el-drawer>
  </div>
</template>

<script>
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';
import { formRequest } from '@/apis/data/form';
import { flowEngine } from '@/apis/data/workflow';
const showStatusList = [0, -1];
export default {
  name: 'WidgetOuterForm',
  components: {
    PcParserNew: () =>
      import('@/custom-component/form/newParser/PcParserNew.vue'),
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData', 'editFields'],
  data() {
    return {
      loading: true,
      outerFormData: null,
      outerNeedFlow: false,
      outerFormUuid: '',
      outerObjectUuid: '',
      outerFormMode: 'add',
      outerNewFlow: false,
      outerFormDisplay: {},
      outerFormType: 1,
      outerFormShowType: 1,
      outerFormVisible: false,
      outerFormTitle: '外部表单',
      btnDisabled: false,
      editFieldsVal: {},
      showFooter: true,
    };
  },
  created() {
    this.getOuterFormDesign();
  },
  computed: {
    canEditField() {
      return function (formId) {
        if (!parseInt(this.element.value)) return false;
        if (this.editFields) {
          const editFormId = Object.keys(this.editFields);
          if (editFormId.length > 0 && editFormId.includes(formId)) {
            return this.editFields[formId];
          }
        }
        return this.disabled;
      };
    },
  },
  methods: {
    changeSkeleton() {},
    async getOuterFormDesign() {
      const formDesignId = this.element.config.__config__.formDesignId;
      const url = '/api/metadata/transformuuid/' + formDesignId;
      const response = await formRequest('get', url, {});
      const resData = response.data.data;
      const needFlow = !!resData?.need_flow;
      if (!parseInt(this.element.value) && this.element.config?.disabled) {
        this.btnDisabled = true;
      } else {
        this.btnDisabled = false;
      }
      if (!parseInt(this.element.value)) {
        this.element.config.__slot__.default = '点击发起';
      } else {
        this.element.config.__slot__.default = '点击查看';
      }
      if (needFlow) {
        const flow = await flowEngine.designShow({ module: formDesignId });
        const isNewFlow = flow.data.data.canvas_data?.cells;
        if (isNewFlow) {
          this.outerNewFlow = true;
        } else {
          this.outerNewFlow = false;
        }
      }
      this.element.config.__config__.designData = resData;
      // this.outerFormData = this.element.config.__config__.designData
      this.loading = false;
    },
    // 打开外部表单
    openOuterForm() {
      const element = this.element;
      this.outerFormData = element.config.__config__.designData;
      this.outerNeedFlow = !!element.config.__config__.designData?.need_flow;
      this.outerFormMode = parseInt(element.value) > 0 ? 'detail' : 'add';
      this.outerFormTitle = element.config.__config__.label;
      this.outerFormShowType = element.config.__config__.showType;
      this.outerFormType = element.config.__config__.formType;
      this.outerFormUuid = element.config.__config__.formDesignId;
      this.outerObjectUuid = element.config.__config__.objectUuid;

      if (parseInt(element.value)) {
        //数据详情回显
        const url = 'api/mapi',
          method = 'POST';
        let ruuids = [];
        for (let i = 0; i < this.outerFormData.fields.length; i++) {
          let item = this.outerFormData.fields[i];
          if (item.__config__.tagIcon == 'form') {
            ruuids.push({ relationship_uuid: item.__vModel__ });
          }
        }
        const param = {
          object_uuid: this.outerObjectUuid,
          view_uuid: '',
          data_id: element.value,
          ruuids,
          __method_name__: 'dataInfo',
        };
        formRequest(method, url, param).then((res) => {
          let data = res.data.data;
          this.outerFormDisplay = data;
          this.outerFormVisible = true;
          if (this.element.value) {
            if (data.recover?.cells) {
              this.outerNewFlow = true;
            } else {
              this.outerNewFlow = false;
            }
          }
          if (data.editFields) {
            this.editFieldsVal = data.editFields;
          }
          this.showFooter =
            showStatusList.includes(+data.flow_engine_status) ||
            !data.flow_engine_status;
        });
      } else {
        this.outerFormVisible = true;
      }
    },
    closeModal() {
      this.outerFormVisible = false;
      this.$nextTick(() => {
        try {
          const modalBox = document.querySelector('.v-modal');
          modalBox.style.zIndex = '3000';
        } catch (err) {
          console.log(err, '切换显示修复层级问题--');
        }
      });
    },
    confirmSubmit() {
      this.$refs.outerFormParser.handleSubmit();
    },
    async outerFormSubmit(formSubmitData) {
      if (!formSubmitData) {
        formSubmitData = this.$refs.outerFormParser.getFormData();
      }
      if (this.outerNewFlow) {
        formSubmitData.new_flow = true;
      }
      if (this.outerObjectUuid && this.outerFormType === 1) {
        const url = '/api/mapi';
        const data = {
          __method_name__: parseInt(this.element.value)
            ? 'updateData'
            : 'createData',
          object_uuid: this.outerObjectUuid,
          ...(parseInt(this.element.value) && { data_id: this.element.value }),
        };
        Object.assign(data, formSubmitData);
        let response = await formRequest('post', url, data);
        if (response.data.code === 200) {
          const id = response.data.data.id;
          this.element.value = id;
          this.outerFormVisible = false;
          this.$message.success(response.data.msg);
          this.element.config.__slot__.default = '点击查看';
        } else {
          this.$message.error(response.data.msg);
        }
      }
    },
  },
  watch: {
    element: {
      handler() {
        this.getOuterFormDesign();
      },
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.common-dialog) {
  .el-dialog__body {
    padding: 0;
  }
}

.dialog-main {
  padding: 0 15px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
