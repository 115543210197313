<!--
    @name: widget-radio
    @description：widget-radio
    @author: ZengWei
    @date: 2022-03-25 09:34
-->
<template>
  <div class="radio-group">
    <div v-if="disabled || element.config.disabled" class="input-show">
      {{ getRadioValue }}
    </div>
    <el-radio-group
      v-else
      v-model="element.value"
      :disabled="disabled || element.config.disabled"
      @change="triggerInteractive"
    >
      <template v-if="element.config.__config__.optionType === 'default'">
        <el-radio
          v-for="(val, key) in element.config.__slot__.options"
          :label="'' + val.value"
          :key="key"
          >{{ val.label }}
        </el-radio>
      </template>
      <template v-else>
        <el-radio-button
          v-for="(val, key) in element.config.__slot__.options"
          :label="'' + val.value"
          :key="key"
          >{{ val.label }}
        </el-radio-button>
      </template>
    </el-radio-group>
  </div>
</template>

<script>
import { RadioGroup, Radio, RadioButton } from 'element-ui';
import formItemMinix from '@/custom-component/form/newParser/scripts/formItemMinix';

export default {
  name: 'WidgetRadio',
  components: {
    'el-radio-group': RadioGroup,
    'el-radio': Radio,
    'el-radio-button': RadioButton,
  },
  mixins: [formItemMinix],
  props: ['element', 'disabled', 'displayData'],
  data() {
    return {};
  },
  computed: {
    getRadioValue() {
      const data = this.element.config.__slot__.options;
      const value = this.element.value;
      if (Array.isArray(data) && data?.length && value) {
        let result = data.find(
          (item) => item?.value?.toString() === value.toString()
        )?.label;
        if (result) {
          return result;
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    },
  },
  created() {
    this.triggerInteractive(this.element.value);
  },
  methods: {
    triggerInteractive(val) {
      this.triggerFlowBranch(val); // 值改变触发流程分支
      this.$emit('change-calc');
      const interact = this.element.config.__config__.interact;
      const effect = interact.effect;
      const condition = interact.condition;
      if (effect === 'showhide') {
        let trueData = condition.filter((item) => {
          if (typeof item.condV === 'string' && item.condV.indexOf(',')) {
            let arr = item.condV.split(',');
            return arr.includes(val) || arr.includes(val.toString());
          }
          return item.condV == val;
        });
        let compList = Array.from(condition, (item) => item.comp).flat();
        let compShowHide = {};
        for (let item of compList) {
          compShowHide[item] = false;
        }
        if (trueData.length) {
          for (let eleItem of trueData) {
            let compShow = eleItem.comp;
            for (let item of compShow) {
              compShowHide[item] = true;
            }
          }
        }
        this.$emit('trigger-active', compShowHide);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.radio-group {
  .el-radio-group {
    margin-top: -3px;
  }
}
</style>
